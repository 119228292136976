/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../App/AppContext';
import { TrackingDashboardLayout } from './styles';

const TrackingDashboard = () => {
  const { currentUser } = useContext(AppContext);

  return (
    <TrackingDashboardLayout>
      <h2>
        Tracking Dashboard (User:
        {currentUser.id}
        )
      </h2>
      <NavLink
        to="/tracking/measurements"
        exact
      >
        Measurements
      </NavLink>
    </TrackingDashboardLayout>
  );
};

export default TrackingDashboard;
