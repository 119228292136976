// import Toaster from './toaster';

export default class errorHandler {
  static handleError(err, config = {}) {
    const { status } = err.response;
    if (status === 422 || err.response.data?.errors) {
      if (config.onInvalid) {
        config.onInvalid(err);
      } else {
        /* Toaster.showToast({
          className: 'danger',
          title: 'Error',
          content: `<ul class="error-list"><li>${err.response.data.errors
            .map((error) => error.full_message)
            .join('</li><li>')}</li></ul>`,
          delay: 10000,
        }); TODO */
      }
    }
  }
}
