import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './Login';
import Logout from './Logout';
import Dashboard from '../Dashboard';
import Movements from '../Movements';
import Tracking from '../Tracking';
import LookupManagement from '../LookupManagement';

const Routes = () => (
  <Switch>
    <Route exact path={['/', '/home']} component={Dashboard} />
    <Route exact path="/movements" component={Movements} />
    <Route exact path="/tracking*" component={Tracking} />
    <Route exact path="/lookups*" component={LookupManagement} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
  </Switch>
);

export default Routes;
