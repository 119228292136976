/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { AppContext } from './AppContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { currentUser, setCurrentUser } = useContext(AppContext);

  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangePassword = (e) => setPassword(e.target.value);

  const handleLogin = (e) => {
    e.preventDefault();

    AuthService.login(email, password).then(
      () => {
        const user = AuthService.getCurrentUser();
        if (user) {
          setCurrentUser(user);
        } else {
          setMessage('invalid credentials');
        }
      },
      (error) => {
        const resMessage = (error.response
          && error.response.data
          && error.response.data.message)
          || error.message
          || error.toString();
        setMessage(resMessage);
      },
    );
  };

  if (currentUser) {
    return (
      <Redirect to="/" push />
    );
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
          style={{ height: 100, width: 100 }}
        />

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            className="form-control"
            name="email"
            value={email}
            onChange={onChangeEmail}
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            name="password"
            value={password}
            onChange={onChangePassword}
          />
        </div>

        <div className="form-group">
          <button className="btn btn-primary btn-block" type="button" onClick={handleLogin}>
            <span>Login</span>
          </button>
        </div>

        {message && (
          <div className="form-group">
            <div
              className="alert alert-danger"
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
