/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import PubSub from 'pubsub-js';
import { AppContext } from '../../App/AppContext';
import { Icon } from '../components';
import {
  LinkItem,
  Links,
  NavBar,
  NavBarTrigger,
} from './styles';

const renderLinkItem = (match, text, iconType, path) => {
  const isImplemented = !!path;

  const linkItemProps = isImplemented
    ? { as: NavLink, exact: true, to: `${path}` }
    : { as: 'div' };

  return (
    <LinkItem {...linkItemProps}>
      {iconType && (<Icon type={iconType} />)}
      {text}
    </LinkItem>
  );
};

const Nav = () => {
  const { currentUser } = useContext(AppContext);
  const match = useRouteMatch();
  const [showMenu, setShowMenu] = useState(false);
  const handleLocationChanged = () => {
    setShowMenu(false);
  };
  PubSub.subscribe('location-changed', handleLocationChanged);

  return (
    <NavBar>
      <NavBarTrigger onClick={() => setShowMenu((prev) => !prev)}>
        <Icon code="menu" />
      </NavBarTrigger>
      <Links show={showMenu}>
        {renderLinkItem(match, 'Home', null, '/home')}
        {currentUser && renderLinkItem(match, 'Movements', null, '/movements')}
        {currentUser && renderLinkItem(match, 'Tracking', null, '/tracking')}
        {currentUser && renderLinkItem(match, 'Lookup Management', null, '/lookups')}
        {currentUser && renderLinkItem(match, 'Logout', null, '/logout')}
        {!currentUser && renderLinkItem(match, 'Login', null, '/login')}
      </Links>
    </NavBar>
  );
};

export default Nav;
