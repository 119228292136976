/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { arrayOf, func, object } from 'prop-types';
import NumberPicker from '../inputs/NumberPicker';
import TextArea from '../inputs/TextArea';
import TextField from '../inputs/TextField';
import Toggle from '../inputs/Toggle';
import Button from '../Button';
import AttachmentUploader from '../AttachmentUploader';
import { FormContainer, FormField, FormLabel, FormInput, FormActions } from './styles';

const Form = ({ data, fields, onFieldChange, onSaveClick }) => {
  const formField = (field) => {
    const changeFunc = (newValue) => {
      const changedProps = {};
      changedProps[field.name] = newValue;
      onFieldChange(changedProps);
    };
    const fieldProps = {
      value: data ? data[field.name] : null,
      onChange: changeFunc,
      ...field.props || {},
    };
    let input = <TextField {...fieldProps} />;
    if (field.type === 'text') {
      input = <TextArea {...fieldProps} />;
    }
    if (field.type === 'toggle') {
      fieldProps.checked = data && data[field.name];
      input = (
        <Toggle
          id={`toggle_${field.name}`}
          checked={(data && data[field.name])}
          onChange={changeFunc}
        />
      );
    }
    if (field.type === 'image') {
      input = (
        <AttachmentUploader
          handleAttachmentChange={changeFunc}
          multiple={field.props && field.props.multiple}
          accept="image/*"
        />
      );
    }
    if (field.type === 'video') {
      input = (
        <AttachmentUploader
          handleAttachmentChange={changeFunc}
          multiple={field.props && field.props.multiple}
          accept="video/*"
        />
      );
    }
    if (field.type === 'number') {
      fieldProps.checked = data && data[field.name];
      input = (
        <NumberPicker
          name={`number_${field.name}`}
          {...fieldProps}
        />
      );
    }

    return (
      <FormField key={field.name}>
        <FormLabel>{field.label}</FormLabel>
        <FormInput>{input}</FormInput>
      </FormField>
    );
  };

  return (
    <FormContainer>
      {data && fields.map((x) => formField(x))}
      <FormActions>
        <Button content="Save" onClick={onSaveClick} />
      </FormActions>
    </FormContainer>
  );
};

Form.propTypes = {
  fields: arrayOf(object),
  data: object,
  onFieldChange: func.isRequired,
  onSaveClick: func.isRequired,
};

Form.defaultProps = {
  fields: [],
  data: {},
};

export default Form;
