/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { AppContext } from '../App/AppContext';
import { DashboardLayout } from './styles';
import WorkoutManager from '../shared/components/WorkoutManager';

const Dashboard = () => {
  const { currentUser } = useContext(AppContext);

  return (
    <DashboardLayout>
      <h2>
        Dashboard
        {currentUser && (
          <>
            (
            {currentUser.id}
            )
          </>
        )}
      </h2>

      <div>
        <h3>Try a Workout</h3>
        <WorkoutManager />
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
