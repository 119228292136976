import React, { useState, useEffect } from 'react';
import WorkoutService from '../../../services/WorkoutService';

const WorkoutManager = () => {
  const [workout, setWorkout] = useState('');
  const [bodyParts, setBodyParts] = useState('');
  const [movementCount, setMovementCount] = useState('');
  const [summary, setSummary] = useState([]);

  const handleWorkoutChange = (e) => setWorkout(e.target.value);
  const handleBodyPartsChange = (e) => {
    setBodyParts(e.target.value);
    setWorkout('');
  };
  const handleMovementCountChange = (e) => {
    setMovementCount(e.target.value);
    setWorkout('');
  };

  const fetchWorkout = () => {
    if (workout > 0) {
      WorkoutService.summarize(workout).then(
        (response) => {
          setSummary(response.data);
          setWorkout(response.data[1].split()[1]);
          setBodyParts('');
          setMovementCount('');
        },
        (error) => {
          console.log(error);
        },
      );
    } else {
      WorkoutService.generate(bodyParts, movementCount).then(
        (response) => {
          console.log('response', response.data);
          setSummary(response.data);
          setWorkout(response.data[1].split(/\(|\)/)[1]);
        },
        (error) => {
          console.log(error);
        },
      );
    }
  };

  const handleClick = () => {
    fetchWorkout();
  };

  useEffect(() => {
    fetchWorkout();
  }, []);

  return (
    <>
      <div style={{ height: '24px' }} />

      <input type="text" value={workout} onChange={handleWorkoutChange} placeholder="workout ID" />
      <div style={{ height: '24px' }} />
      <input type="text" value={movementCount} onChange={handleMovementCountChange} placeholder="# of movements" />
      <div style={{ height: '24px' }} />
      <input type="text" value={bodyParts} onChange={handleBodyPartsChange} placeholder="parts (e.g. chest, back)" />
      <div style={{ height: '24px' }} />
      <button type="button" onClick={handleClick}>Summarize</button>
      <div dangerouslySetInnerHTML={{ __html: summary.join('<br />') }} />
    </>
  );
};

export default WorkoutManager;
