/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { AppContext } from '../App/AppContext';
import { MovementLayout } from './styles';

const Movements = () => {
  const { currentUser } = useContext(AppContext);

  return (
    <MovementLayout>
      <h2>
        Movements (User:
        {currentUser.id}
        )
      </h2>
    </MovementLayout>
  );
};

export default Movements;
