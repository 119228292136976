/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LookupManagementLayout } from './styles';
import LookupManagementDashboard from './Dashboard';
import FitnessLevels from './FitnessLevels';
import Demonstrators from './Demonstrators';

const LookupManagement = ({ match }) => (
  <LookupManagementLayout>
    <Switch>
      <Route exact path={`${match.path}/fitness_levels`} component={FitnessLevels} />
      <Route exact path={`${match.path}/demonstrators`} component={Demonstrators} />
      <Route exact path={`${match.path}`} component={LookupManagementDashboard} />
    </Switch>
  </LookupManagementLayout>
);

export default LookupManagement;
