import React from 'react';
import { string } from 'prop-types';
import fontawesome from '@fortawesome/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/fontawesome-free-solid';

fontawesome.library.add(faBars);

const iconDefs = { menu: faBars };

const propTypes = {
  className: string,
  code: string.isRequired,
  type: string,
  size: string,
};

const defaultProps = {
  className: undefined,
  type: 'fas',
  size: '1x',
};

const Icon = ({ code, type, ...iconProps }) => (
  <FontAwesomeIcon {...iconProps} icon={iconDefs[code]} />
);

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
