import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_URL;

const getList = () => axios.get(`${API_URL}workouts/`, { headers: authHeader() });
const getDetail = (id) => axios.get(`${API_URL}workouts/${id}`, { headers: authHeader() });
const summarize = (id) => axios.get(`${API_URL}workout_summaries/${id}`, { headers: authHeader() });
const generate = (bodyParts, movementCount) => axios.get(`${API_URL}workout_summaries/`,
  { headers: authHeader(), params: { body_parts: bodyParts, movement_count: movementCount } });

export default {
  getList,
  getDetail,
  summarize,
  generate,
};
