import styled from 'styled-components/macro';
import { mixin } from '../../utils/styles';

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: #FFF;
  border-bottom: solid 1px #CCC;
  ${mixin.font()}
`;
export const FormField = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  border-bottom: solid 1px #CCC;
`;
export const FormLabel = styled.div`
  border-right: solid 1px #CCC;
  padding: 0.25rem;

`;
export const FormInput = styled.div`
  padding: 0.25rem;
`;
export const FormActions = styled.div`
  padding: 0.25rem;
`;
