import { useQuery } from 'react-query';
import errorHandler from '../../helpers/errorHandler';
import LookupService from '../../services/LookupService';

export default function useFitnessLevels() {
  const fitnessLevels = useQuery(
    ['fitness_levels'],
    async () => {
      const resp = await LookupService.getList('fitness_levels')
        .catch(errorHandler.handleError);

      return resp.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const saveFitnessLevel = (fitnessLevel, callback) => {
    if (fitnessLevel.id) {
      LookupService.update('fitness_levels', fitnessLevel.id, fitnessLevel)
        .then((resp) => {
          callback(resp.data);
        })
        .catch(errorHandler.handleError);
    } else {
      LookupService.create('fitness_levels', fitnessLevel)
        .then((resp) => {
          callback(resp.data);
        })
        .catch(errorHandler.handleError);
    }
  };

  const { data: newFitnessLevel } = useQuery(
    ['new_fitness_level'],
    async () => {
      const resp = await LookupService.newRecord('fitness_levels')
        .catch(errorHandler.handleError);

      return resp.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    fitnessLevels,
    newFitnessLevel,
    saveFitnessLevel,
  };
}
