import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PubSub from 'pubsub-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import useCurrentUser from './useCurrentUser';
import useLocalStorage from '../shared/hooks/useLocalStorage';
import { AppContext } from './AppContext';
import { AppLayout, AppLayoutHeader } from './styles';
import Nav from '../shared/Nav';
import Routes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';

config.autoAddCss = false;
const queryClient = new QueryClient();

const App = () => {
  const user = useCurrentUser();
  const [currentUser, setCurrentUser] = useLocalStorage('current_user', user);
  const location = useLocation();

  useEffect(() => {
    PubSub.publish('location-changed', location);
  }, [location]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider
          value={{ currentUser, setCurrentUser, queryClient }}
        >
          <AppLayout>
            <AppLayoutHeader>
              <Nav />
            </AppLayoutHeader>
            <Routes />
          </AppLayout>
        </AppContext.Provider>
      </QueryClientProvider>
    </>
  );
};

export default App;
