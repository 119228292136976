import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const register = (email, password) => axios
  .post(`${API_URL}signup`, {
    user: {
      email,
      password,
      password_confirmation: password,
    },
  })
  .then((response) => {
    if (response.headers.authorization) {
      localStorage.setItem('user', JSON.stringify({ accessToken: response.headers.authorization, ...response.data }));
    }

    return response;
  });

const login = (username, password) => axios
  .post(`${API_URL}login`, { user: { email: username, password } })
  .then((response) => {
    if (response.headers.authorization) {
      localStorage.setItem('user', JSON.stringify({ accessToken: response.headers.authorization, ...response.data }));
    }

    return response.data;
  });

const logout = () => axios
  .delete(`${API_URL}logout`)
  .then(() => {
    localStorage.removeItem('user');
  });

const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));

export default {
  register,
  login,
  logout,
  getCurrentUser,
};
