import styled from 'styled-components/macro';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';

export const AppLayout = styled.div`
  padding: 44px 0 0;
  position: relative;
  overflow-y: auto;
  margin: 0 auto;
  background: #EEE;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
`;

export const AppLayoutHeader = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;
