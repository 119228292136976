/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { any, func, string } from 'prop-types';
import { TextFieldInput } from './styles';

const TextField = ({ onChange, placeholder, value }) => (
  <TextFieldInput
    value={value || ''}
    placeholder={placeholder}
    onChange={({ target }) => onChange(target.value)}
  />
);

TextField.propTypes = {
  onChange: func,
  placeholder: string,
  value: any,
};

TextField.defaultProps = {
  onChange: () => { },
  placeholder: null,
  value: null,
};

export default TextField;
