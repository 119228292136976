/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from 'react';
import { reactFormatter } from 'react-tabulator';
import { AppContext } from '../../App/AppContext';
import Form from '../../shared/components/Form';
import Grid from '../../shared/components/Grid';
import Header from '../../shared/components/Header';
import Thumbnail from '../../shared/components/Thumbnail';
import ThumbnailColumn from '../../shared/components/ThumbnailColumn.jsx';
import useDemonstrators from './useDemonstrators';
import { Content, Detail, DemonstratorsLayout, List } from './styles';

const Demonstrators = () => {
  const {
    demonstrators,
    newDemonstrator,
    saveDemonstrator,
  } = useDemonstrators();
  const [currentRecord, setCurrentRecord] = useState(null);
  const { queryClient } = useContext(AppContext);
  const columns = [
    { title: 'ID', field: 'id', width: 80 },
    {
      title: 'Image',
      field: 'imageUrl',
      width: 120,
      formatter: reactFormatter(
        <ThumbnailColumn
          computeImageProps={(data) => ({
            src: data.imageUrl,
            height: 60,
          })}
        />,
      ),
    },
    { title: 'Value', field: 'value' },
    { title: 'Description', field: 'description', widthGrow: 2 },
    { title: 'Active', field: 'active', width: 100 },
    { title: 'Rank', field: 'rank', width: 100 },
  ];

  const fields = [
    { label: 'Value', name: 'value', type: 'string' },
    { label: 'Description', name: 'description', type: 'text' },
    { label: 'Active', name: 'active', type: 'toggle' },
    { label: 'Rank', name: 'rank', type: 'number', props: { min: 0 } },
    { label: 'Image', name: 'image', type: 'image' },
  ];

  const onFieldChange = (updatedProps) => {
    setCurrentRecord((prev) => ({
      ...prev,
      ...updatedProps,
    }));
  };

  const handleSaveClick = () => {
    saveDemonstrator(currentRecord, () => {
      queryClient.refetchQueries('demonstrators');
    });
  };

  const onNewClick = () => {
    setCurrentRecord(newDemonstrator);
  };

  return (
    <DemonstratorsLayout>
      <Header
        title="Demonstrators"
        actions={[
          {
            buttonContent: 'New Demonstrator',
            onClick: onNewClick,
          },
        ]}
      />
      <Content>
        <List>
          {!demonstrators.isLoading && demonstrators.data && (
            <Grid
              columns={columns}
              data={demonstrators.data.entries}
              setCurrentRecord={setCurrentRecord}
            />
          )}
        </List>
        <Detail>
          <Thumbnail record={currentRecord} className="infoImage" style={{ width: 120 }} />
          {currentRecord && (
            <Form
              data={currentRecord}
              fields={fields}
              onFieldChange={onFieldChange}
              onSaveClick={handleSaveClick}
            />
          )}
        </Detail>
      </Content>
    </DemonstratorsLayout>
  );
};

export default Demonstrators;
