/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { func, number, string } from 'prop-types';
import { NumberSelect } from './styles';

const NumberPicker = ({ onChange, max, min, name, step, value }) => {
  const options = [];
  for (let i = min; i <= max; i += step) {
    options.push({ label: i, value: i });
  }

  const initialValue = options.find((o) => o.value === value);

  const handleChange = (newValue) => {
    onChange(newValue.value);
  };

  return (
    <NumberSelect
      name={name}
      value={initialValue}
      options={options}
      onChange={handleChange}
      menuPlacement="auto"
      maxMenuHeight={150}
    />
  );
};

NumberPicker.propTypes = {
  onChange: func,
  max: number,
  min: number,
  name: string.isRequired,
  step: number,
  value: number,
};

NumberPicker.defaultProps = {
  onChange: () => { },
  max: 100,
  min: 0,
  step: 1,
  value: null,
};

export default NumberPicker;
