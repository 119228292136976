/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TrackingLayout } from './styles';

import TrackingDashboard from './Dashboard';
import TrackingMeasurements from './Measurements';

const Tracking = ({ match }) => (
  <TrackingLayout>
    <Switch>
      <Route exact path={`${match.path}/measurements`} component={TrackingMeasurements} />
      <Route exact path={`${match.path}`} component={TrackingDashboard} />
    </Switch>
  </TrackingLayout>
);

export default Tracking;
