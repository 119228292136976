/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { createRef, useMemo } from 'react';
import { arrayOf, func, object } from 'prop-types';
import { GridContainer, Table } from './styles';

const Grid = ({ columns, data, setCurrentRecord, ...options }) => {
  const tableRef = createRef();
  const gridOptions = {
    layout: 'fitColumns',
    debugInvalidOptions: false,
    movableColumns: true,
    responsiveLayout: 'collapse',
    selectable: 1,
    rowSelected: (row) => {
      setCurrentRecord(row.getData());
    },
    reactiveData: true,
    ...options,
  };

  const renderTable = useMemo(
    () => (
      <Table
        ref={tableRef}
        columns={columns}
        data={data}
        options={gridOptions}
      />
    ),
    [data],
  );

  return (
    <GridContainer>
      {renderTable}
    </GridContainer>
  );
};

Grid.propTypes = {
  columns: arrayOf(object),
  data: arrayOf(object),
  setCurrentRecord: func,
};

Grid.defaultProps = {
  columns: [],
  data: [],
  setCurrentRecord: () => { },
};

export default Grid;
