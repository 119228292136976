import React from 'react';
import { bool, func, string } from 'prop-types';
import { DirectUploadProvider } from 'react-activestorage-provider';
import authHeader from '../../../services/AuthHeader';

const propTypes = {
  multiple: bool,
  accept: string,
  handleAttachmentChange: func,
};

const defaultProps = {
  multiple: false,
  accept: 'image/*',
  handleAttachmentChange: null,
};

const apiUrl = new URL(process.env.REACT_APP_API_URL);

const AttachmentUploader = ({ multiple, accept, handleAttachmentChange }) => (

  <DirectUploadProvider
    multiple={multiple}
    directUploadsPath="/api/direct_uploads"
    origin={{
      host: apiUrl.hostname,
      port: apiUrl.port,
      protocol: apiUrl.protocol,
    }}
    headers={authHeader()}
    onSuccess={(signedIds) => handleAttachmentChange(signedIds[0])}
    render={({ handleUpload, uploads, ready }) => (
      <div>
        <input
          type="file"
          accept={accept}
          disabled={!ready}
          onChange={(e) => handleUpload(e.currentTarget.files)}
        />

        {uploads.map((upload) => {
          switch (upload.state) {
            case 'waiting':
              return (
                <p key={upload.id}>
                  Waiting to upload
                  {' '}
                  {upload.file.name}
                </p>
              );
            case 'uploading':
              return (
                <p key={upload.id}>
                  Uploading
                  {' '}
                  {upload.file.name}
                  :
                  {' '}
                  {upload.progress}
                  %
                </p>
              );
            case 'error':
              return (
                <p key={upload.id}>
                  Error uploading
                  {' '}
                  {upload.file.name}
                  :
                  {' '}
                  {upload.error}
                </p>
              );
            case 'finished':
              return (
                <p key={upload.id}>
                  Finished uploading
                  {' '}
                  {upload.file.name}
                </p>
              );
            default:
              return <></>;
          }
        })}
      </div>
    )}
  />

);

AttachmentUploader.propTypes = propTypes;
AttachmentUploader.defaultProps = defaultProps;

export default AttachmentUploader;
