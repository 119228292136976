import styled from 'styled-components/macro';
import Navbar from 'react-bootstrap/Navbar';

export const HeaderContainer = styled(Navbar)`
  padding: 0.5rem 1rem;
  border-bottom: solid 1px #CCC;
`;
export const Title = styled(Navbar.Brand)`
  font-weight: 600;
  margin-right: auto !important;
`;
export const Actions = styled.div`

`;
