/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { ToggleInput } from './styles';

const Toggle = ({ checked, disabled, id, label, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };
  return (
    <ToggleInput
      id={id}
      className="toggle"
      type="switch"
      label={label}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

Toggle.propTypes = {
  checked: bool,
  disabled: bool,
  id: string.isRequired,
  label: node,
  onChange: func,
};

Toggle.defaultProps = {
  checked: false,
  disabled: false,
  label: null,
  onChange: () => { },
};

export default Toggle;
