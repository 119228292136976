/* eslint-disable react/no-array-index-key */
import React from 'react';
import { arrayOf, func, node, shape, string } from 'prop-types';
import Button from '../Button';
import { Actions, HeaderContainer, Title } from './styles';

const Header = ({ actions, title, ...navbarProps }) => (
  <HeaderContainer bg="light" {...navbarProps}>
    <Title>
      {title}
    </Title>
    <Actions>
      {actions.map(({ buttonContent, ...buttonProps }, index) => (
        <Button key={`action-${index}`} content={buttonContent} {...buttonProps} />
      ))}
    </Actions>
  </HeaderContainer>
);

Header.propTypes = {
  action: arrayOf(shape({
    buttonContent: node,
    href: string,
    onClick: func,
  })),
  title: string,
};

Header.defaultProps = {
  action: [],
  title: null,
};

export default Header;
