import styled from 'styled-components/macro';
import { ReactTabulator } from 'react-tabulator';
import { mixin } from '../../utils/styles';

export const GridContainer = styled.div`
  position: relative;
  ${mixin.font()}
`;

export const Table = styled(ReactTabulator)`
  ${mixin.font()}
`;
