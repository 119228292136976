import { useQuery } from 'react-query';
import errorHandler from '../../helpers/errorHandler';
import LookupService from '../../services/LookupService';

export default function useDemonstrators() {
  const demonstrators = useQuery(
    ['demonstrators'],
    async () => {
      const resp = await LookupService.getList('demonstrators')
        .catch(errorHandler.handleError);

      return resp.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const saveDemonstrator = (demonstrator, callback) => {
    if (demonstrator.id) {
      LookupService.update('demonstrators', demonstrator.id, demonstrator)
        .then((resp) => {
          callback(resp.data);
        })
        .catch(errorHandler.handleError);
    } else {
      LookupService.create('demonstrators', demonstrator)
        .then((resp) => {
          callback(resp.data);
        })
        .catch(errorHandler.handleError);
    }
  };

  const { data: newDemonstrator } = useQuery(
    ['new_demonstrator'],
    async () => {
      const resp = await LookupService.newRecord('demonstrators')
        .catch(errorHandler.handleError);

      return resp.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    demonstrators,
    newDemonstrator,
    saveDemonstrator,
  };
}
