import axios from 'axios';
import authHeader from './AuthHeader';

const API_URL = process.env.REACT_APP_API_URL;
const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;

const getList = (lookupName) => axios.get(`${API_URL}${lookupName}`, { headers: authHeader() });
const getDetail = (lookupName, id) => axios.get(`${API_URL}${lookupName}/${id}`, { headers: authHeader() });
const newRecord = (lookupName) => axios.get(`${API_URL}${lookupName}/new`, { headers: authHeader() });
const create = (lookupName, data) => axios.post(`${API_URL}${lookupName}`, data, { headers: authHeader() });
const update = (lookupName, id, data) => axios.put(`${API_URL}${lookupName}/${id}`, data, { headers: authHeader() });
const uploadImage = (data) => axios.post(UPLOAD_URL, data, { headers: authHeader() });

export default {
  getList,
  getDetail,
  create,
  newRecord,
  update,
  uploadImage,
};
