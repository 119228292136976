/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../App/AppContext';
import { LookupManagementDashboardLayout } from './styles';

const LookupManagementDashboard = () => {
  const { currentUser } = useContext(AppContext);

  return (
    <LookupManagementDashboardLayout>
      <h2>
        Lookup Manager Dashboard (User:
        {currentUser.id}
        )
      </h2>
      <NavLink
        to="/lookups/fitness_levels"
        className="nav-link"
        exact
      >
        Fitness Levels
      </NavLink>

      <NavLink
        to="/lookups/demonstrators"
        className="nav-link"
        exact
      >
        Demonstrators
      </NavLink>
    </LookupManagementDashboardLayout>
  );
};

export default LookupManagementDashboard;
