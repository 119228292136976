import React from 'react';

export default function ThumbnailColumn(props) {
  const { cell, computeImageProps } = props;
  // eslint-disable-next-line no-underscore-dangle
  const rowData = cell._cell.row.data;
  const imageProps = {
    className: 'grid-thumb',
    alt: '',
    ...computeImageProps(rowData),
  };
  if (imageProps.height) {
    imageProps.style = {
      display: 'inline-block',
      height: imageProps.height,
    };
  }

  const onImageLoad = () => {
    cell.checkHeight();
    document.body.dispatchEvent(new Event('table-content-loaded'));
  };

  const img = (
    <img
      alt=""
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
      onLoad={onImageLoad}
    />
  );

  return <>{img}</>;
}
