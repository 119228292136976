import React from 'react';
import { node } from 'prop-types';
import { ButtonComponent } from './styles';

const Button = ({ content, ...buttonProps }) => (
  <ButtonComponent {...buttonProps}>
    {content}
  </ButtonComponent>
);

Button.propTypes = { content: node.isRequired };

export default Button;
