import styled from 'styled-components/macro';
import { color, mixin } from '../utils/styles';

export const NavBar = styled.div`
  padding: 0 0.5rem;
  display: flex;
  background: #000;
  color: #FFF;
  height: 44px;
  justify-content: flex-start;
  align-items: center;
  ${mixin.boxShadowMedium}
`;
export const NavBarTrigger = styled.a`
  color: #FFF;
  cursor: pointer;
`;

export const Links = styled.div`
  position: absolute;
  top: 44px;
  left: 4px;
  right: 4px;
  border: solid 1px #DDD;
  background: #FFF;
  border-top: 0;
  max-height: ${(props) => (props.show ? '80vh' : '0')};
  overflow: hidden;
  flex-direction: column;
  ${mixin.boxShadowDropdown}
  ${mixin.easeTransition({ props: 'max-height' })}
`;

export const LinkItem = styled.a`
  padding: 0.5rem;
  line-height: 2rem;
  display: block;
  border-top: solid 1px #DDD;
  text-decoration: none;
  color: #000;
  &:first-child{
    border-top: 0;
  }
  &:hover{
    background: rgba(0,0,0,0.1);
  }
  &.active {
    color: #000;
    background: #FFF;
    i {
      color: ${color.primary};
    }
  }
`;
