import React from 'react';
import { ThumbnailComponent } from './styles';

const Thumbnail = ({ record, ...imgProps }) => (
  <>
    { record && record.imageUrl && <ThumbnailComponent {...imgProps} src={record.imageUrl} /> }
  </>
);

export default Thumbnail;
