/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { any, func, string } from 'prop-types';
import { TextAreaInput } from './styles';

const TextArea = ({ onChange, placeholder, value }) => (
  <TextAreaInput
    value={value || ''}
    as="textarea"
    className="form-control"
    placeholder={placeholder}
    onChange={({ target }) => onChange(target.value)}
  />
);

TextArea.propTypes = {
  onChange: func,
  placeholder: string,
  value: any,
};

TextArea.defaultProps = {
  onChange: () => { },
  placeholder: null,
  value: null,
};

export default TextArea;
