import styled from 'styled-components/macro';
import { mixin } from '../../shared/utils/styles';

export const FitnessLevelsLayout = styled.div`
  ${mixin.fullHeight}
`;

export const Content = styled.div`
  ${mixin.standardLayout}
`;

export const List = styled.div`

`;

export const Detail = styled.div`

`;
