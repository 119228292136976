import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { AppContext } from './AppContext';

const Logout = () => {
  const { setCurrentUser } = useContext(AppContext);
  AuthService.logout();
  setCurrentUser(null);
  return (
    <Redirect to="/" push />
  );
};

export default Logout;
