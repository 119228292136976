/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { AppContext } from '../../App/AppContext';
import { TrackingMeasurementsLayout } from './styles';

const TrackingMeasurements = () => {
  // eslint-disable-next-line no-unused-vars
  const { currentUser } = useContext(AppContext);

  return (
    <TrackingMeasurementsLayout>
      <h2>
        Measurements (User:
        {currentUser.id}
        )
      </h2>
    </TrackingMeasurementsLayout>
  );
};

export default TrackingMeasurements;
